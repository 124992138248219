<template>
  <div>
    <div>
      <div
        class="
          card-filter-section
          mt-0
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <h3 class="table-title-text">
          My Team Details
        </h3>
        <div class="role-buttons d-flex flex-wrap align-items-center" />
        <div class="role-buttons d-flex flex-wrap align-items-center  justify-content">

          <b-button
            class="back-button"
            @click="Back()"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              width="18"
              height="18"
            />
            <span>Back</span>
          </b-button>
        </div>
      </div>
    </div>
    <!-- user Details -->
    <myteamdtl-shimmer v-if="loading_myteamdtl" />
    <div
      v-else
      class="teamUserinfo"
    >
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="table-title-text">
            User Details
          </h3>
          <div class="userCalender d-flex">
            <div class="hrDaytime">
              <b-input-group class="color-picker-input">
                <month-picker-input
                  :no-default="false"
                  :default-month="Number(month.valueOf())"
                  @change="showDate"
                />
              </b-input-group>
            </div>
            <div class="partTimebtn">
              <span
                :variant="
                  totalResourceHours < 40
                    ? 'outline-success'
                    : totalResourceHours > 119
                      ? 'outline-danger'
                      : 'outline-primary'
                "
                :class="
                  totalResourceHours < 40
                    ? 'availbletimeBtn'
                    : totalResourceHours > 119
                      ? 'fulltimeBtn'
                      : 'pratimeBtn'
                "
              >
                <!-- Part Time -->

                {{
                  totalResourceHours < 40
                    ? "Available"
                    : totalResourceHours > 119
                      ? "Full Time"
                      : "Part Time"
                }}
              </span>
              <span>
                {{
                  totalResourceHours > 119
                    ? "Not Avaiable"
                    : " Available " + (160 - totalResourceHours) + " Hours"
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center userInfo">
          <div>
            <b-avatar
              size="100px"
              :src="users && users.length? users[0].picture:null"
              :text="(users && users.length? users[0].full_name : null) | avatarText"
            />
          </div>
          <div class="teamuserName">
            <h4 v-show="false">
              {{ users && users.length? users[0].full_name : null }}
            </h4>
            <v-select
              v-model="user_id"
              name="status"
              label="name"
              :options="filterMyTeamList"
              :reduce="(user) => user.id"
              :clearable="false"
              class="select-size-sm select-user-nav mb-1"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
              <template #selected-option="option">
                <div style="display: flex; align-items: baseline">
                  <span class="select-user-name">{{ option.name }} </span>
                </div>
              </template>
            </v-select>
            <div>

              <span>
                {{ users && users.length? users[0].email :null }} | {{ users && users.length? users[0].phone :null }}</span>
            </div>

          </div>
        </div>
        <div class="userDiscription d-lg-flex d-block justify-content-between">
          <div class="userDatainfo">
            <ul class="m-0 p-0 d-flex">
              <li>
                <h2>Managed By</h2>
                <span>{{ users && users.length && users[0] && users[0].manage_by_user && users[0].manage_by_user.full_name ? users[0].manage_by_user.full_name :null }}</span>
              </li>
              <li>
                <h2>Reporting To</h2>
                <span>{{ users && users.length && users[0] && users[0].users && users[0].users.full_name }}</span>
              </li>
              <li>
                <h2>Primary Skill(s)</h2>
                <div class="d-flex flex-wrap">
                  <p v-if="users && users.length && users[0] && users[0].primary_skills">
                    <b-badge
                      v-for="(skill, index) in users[0].primary_skills"
                      v-if="skill.pivot.type == 'primary'"
                      :key="index"
                      pill
                      :variant="skillBackground()"
                    >
                      {{ skill.name }}
                    </b-badge>
                  </p>
                </div>
              </li>
              <li>
                <h2>Secondary Skill(s)</h2>
                <div class="d-flex flex-wrap">
                  <p v-if="users && users.length && users[0] && users[0].primary_skills">
                    <b-badge
                      v-for="(skill, index) in users[0].primary_skills"
                      v-if="skill.pivot.type == 'secondary'"
                      :key="index"
                      pill
                      :variant="skillBackground()"
                    >
                      {{ skill.name }}
                    </b-badge>
                  </p>
                </div>
              </li>
              <li>
                <h2>Department</h2>
                <span>{{ users && users.length &&  users[0].department && users[0].department.name }}</span>
              </li>
              <li>
                <h2>Division</h2>
                <span>{{ users && users.length &&  users[0].division && users[0].division.name }}</span>
              </li>
              <li v-show="false">
                <h2>Secondary Skill(s)</h2>
                <div class="d-flex flex-wrap">

                  <p v-if="users && users.length && users[0] && users[0].primary_skills">
                    <b-badge
                      v-for="(skill, index) in users[0].primary_skills"
                      v-if="skill.pivot.type != 'primary'"
                      :key="index"
                      pill
                      :variant="skillBackground()"
                    >
                      {{ skill.name }}
                    </b-badge>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="myteamPiechart">
            <div id="chart">
              <apexchart
                ref="chart"
                type="pie"
                width="210"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- member table start      -->
    <div
      v-if="items && items.length"
      class="myteamTbl myteamDtlTbl detail-tabl mb-3"
    >
      <h3>Day Wise Summary</h3>

      <TableLoader
        v-if="loading"
        :fields="fields"
      />

      <b-table
        v-else
        :items="clockData"
        :fields="fields"
        :sticky-header="stickyHeader"
        :no-border-collapse="noCollapse"
        responsive
      >
        <template #cell(productive_hours)="data">
          <div>

            {{
              secondsToTime(
                data.item.productive_hours * 60
              )
            }}
          </div>
        </template>
        <template #cell(productivity)="data">
          <div>
            {{ data.item.productivity ? data.item.productivity : 0 }}

          </div>
        </template>
        <template #cell(day_out)="data">
          <div>
            {{ data.item.lastOut ? data.item.lastOut.slice(0,-3) : "" }}

          </div>
        </template>
        <template #cell(total_hours)="data">
          <div>

            {{ secondsToTime(data.item.totalHours) }}

          </div>
        </template>

        <template #cell(date)="data">
          <div>
            {{ data.item.date |DateDDMMYYYYFormat }}
          </div>
        </template>
        <template #cell(day_in)="data">
          <div>

            {{ data.item.firstIn.slice(0,-3) }}
          </div>
        </template>

        <template #cell(action)="data">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.summaryPopup
              variant="primary"
              class="btnViewsumm"
              @click="summaryOpen(data.item.date)"
            >

              View Summary
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <div v-else>
      <img
        src="../../assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </div>
    <DayInOut />
    <!-- member table end  -->
  </div>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'
import { eventBus } from '@/main'
import VueApexCharts from 'vue-apexcharts'
import TableLoader from '../../components/loaders/table-loading.vue'
import MyteamdtlShimmer from '../shimmer/MyteamdtlShimmer.vue'
import DayInOut from './summary.vue'

export default {
  name: 'Myteamdetail',
  components: {
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BBadge,
    MonthPickerInput,
    DayInOut,
    TableLoader,
    MyteamdtlShimmer,
    apexchart: VueApexCharts,

  },
  filters: {
    DateDDMMYYYYFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },

  },
  data() {
    return {
      stickyHeader: true,
      clockData: [],
      user_id: this.$route.params.id,
      // clockData: null,
      noCollapse: false,
      loading: true,
      loading_myteamdtl: true,
      // selectedCheck: false,
      modes: ['multi', 'single', 'range'],
      date: {
        from: null,
        to: null,
        month: parseInt(moment().format('MM')),
        year:  moment().format('YYYY'),
      },
      users: [],
      fields: [
        { key: 'date', label: 'Date' },
        { key: 'day_in', label: 'Day In' },
        { key: 'day_out', label: 'Day Out' },
        { key: 'total_hours', label: 'Total Hours' },
        { key: 'productive_hours', label: 'Productive Hours' },
        { key: 'productivity', label: 'Productivity (%) ' },
        { key: 'action', label: 'Action', tdClass: 'actionBtn' },
      ],
      items: [],
      series: [],
      chartOptions: {
        chart: {
          width: 210,
          type: 'pie',
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 10,
            position: 'bottom',
            dropShadow: false,
          },
          dropShadow: {
            enabled: false,
          },
          background: {
            enabled: true,
            foreColor: '#2178FB',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#fff',
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '12px',
            paddingBottom: 0,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#1A60C9', '#7AAEFD', '#2178FB'],
        labels: [],
        responsive: [{
          breakpoint: 575,
          options: {
            chart: {
              width: 150,
            },
            legend: {
              position: 'center',
            },
          },
        }],
      },

    }
  },

  computed: {
    filterMyTeamList() {
      return this.$store.state.app.usersList
    },
    totalResourceHours() {
      let total = 0
      if (
        this.users[0]
        && this.users[0].resource_plan
        && this.users[0].resource_plan.length
      ) {
        this.users[0].resource_plan.forEach(element => {
          total += element.planned_hours ? element.planned_hours : 0
        })
      }

      return total
    },
  },
  watch: {
    user_id() {
      this.userSummary()
    },
  },

  mounted() {
    this.month = parseInt(moment().format('MM'))
    this.year = moment().format('YYYY')
    if (this.$store.state.app.usersList && this.$store.state.app.usersList.length === 0) {
      this.userList()
    }
    this.userSummary()
  },

  methods: {
    totalProductiveHours(data, index) {
      const total = this.ArrSort(data)[this.clockData[index].length - 1].productive_hours
      return total
    },
    totalTimeOfDay(data) {
      let total = 0
      if (data && data.length) {
        data.forEach(element => {
          total += element.time_diff ? element.time_diff : 0
        })
        return total
      }
    },
    showDate(date) {
      this.date = date

      this.month = date.monthIndex < 10 ? date.monthIndex : date.monthIndex
      this.year = date.year
      this.userSummary()
    },
    chart() {
      const data = this.users[0].resource_plan

      let planned_hours = 0
      const series_array = []
      const labels_array = []

      if (data && data.length) {
        data.forEach(element => {
          planned_hours = Math.round(((element.planned_hours * 100) / 160))
          planned_hours = planned_hours > 100 ? 100 : planned_hours
          const actual = element.actual_hours / 60 > 0 ? (element.actual_hours / 60).toFixed(2) : 0
          series_array.push(element.planned_hours)
          labels_array.push(`${element.project.name}<br>  Actual hour :<span style="font-weight: bold;">   ${actual}</span><br>   Planned hour`)
        })

        this.series = series_array
        this.chartOptions.labels = labels_array
      } else {
        this.series = series_array
        this.chartOptions.labels = labels_array
      }
      this.$refs.chart.updateOptions({
        ...this.chartOptions,
      })
    },
    summaryOpen(date) {
      const input = {
        user_id: this.user_id,
        date,
      }
      eventBus.$emit('userSummaryOpen', input)
    },
    /**
     * @description  User Summary
     * @method POST
     * @returns i User Summary
     */
    async userSummary() {
      this.series = []
      this.clockData = []
      this.items = []
      this.loading = true
      this.loading_myteamdtl = false
      const input = {
        user_id: this.user_id,
        year: this.year ? this.year : moment().format('YYYY'),
        month: this.month ? this.month : moment().format('MM'),
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/my-team/day-detail',
        input,
        false,
      )
      if (response && response.data) {
        this.users = response.data.users

        const dayData = response.data.dayClock
        this.items = Object.keys(response.data.dayClock)
        this.chart()

        this.items.sort((a, b) => new Date(b) - new Date(a))
        this.clockData = []
        this.items.forEach(element => {
          const clockData = this.ArrSort(dayData[element])
          const obj = {
            date: element,
            firstIn: clockData && clockData.length ? clockData[0].day_in : '',
            lastOut: clockData && clockData.length ? clockData[clockData.length - 1].day_out : '',
            totalHours: clockData && clockData.length ? this.totalTimeOfDay(clockData) : 0,
            productive_hours: clockData && clockData.length ? clockData[clockData.length - 1].productive_hours : 0,
            productivity: clockData && clockData.length ? clockData[clockData.length - 1].productivity : 0,
          }
          this.clockData.push(obj)
        })
      }
      this.loading = false
    },
    Back() {
      const { query } = this.$route
      this.$router.push({
        path: '/my-team',
        query,
      })
    },

    productivity(productiveHours, dayInHous) {
      if (productiveHours && dayInHous) {
        return (
          ((productiveHours * 60) / dayInHous) * 100 > 100
            ? 100
            : ((productiveHours * 60) / dayInHous) * 100
        ).toFixed(2)
      }
      return 0
    },

    ArrSort(data) {
      return data.sort(({ day_in: a }, { day_in: b }) => (a > b ? 1 : a < b ? -1 : 0))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";
@import "./../../assets/scss/component-css/masterSettingFilter.scss";

.back-time-sec {
  background: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.table-title-text {
  font-size: 20px;
  margin: 0;
  @media (max-width: map-get($mediaSizes , "xl")) {
    font-size: 18px;
  }
}

.userCalender .hrDaytime .input-group .month-picker-input {
  border: none;
  background: #eff5ff;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer !important;
}

</style>
